.thumbnail-container {
  height: 200px;
  max-height: 200px;

  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
  }
}
